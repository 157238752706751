import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../layout";
import SocialLinks from "../../unused-components/SocialLinks/SocialLinks";
import SEO from "../../components/SEO/SEO";
import config from "../../../data/SiteConfig";
import "./post.css";
import MailingList from "../../components/MailingList/mailingList";
import PostTags from "../../components/PostTags/PostTags";

export default function PostTemplate({ data, pageContext }) {
  const { slug } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;
  if (!post.id) {
    post.id = slug;
  }

  let date = new Date(post.date);
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  return (
    <Layout>
      <div>
        <Helmet>
          <title>{`${post.title} | ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postPath={slug} postNode={postNode} postSEO />
        <div className="post-container">
          <div className="post-heading">
            <h1>{post.title}</h1>
            <p>Posted {date.toLocaleDateString('en-US', options)}</p>         
          </div>          
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: postNode.html }} className="post-body" />
          <div className="post-meta">
            <PostTags tags={post.tags} />
            <SocialLinks postPath={slug} postNode={postNode} />
          </div>
          <MailingList/>
        </div>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const postQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        date
        category
        tags
        description
        cover {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED 
              height:350
            )
          }
        }
        coverAlt
      }
      fields {
        slug
        date
      }
    }
  }
`;
