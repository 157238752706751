import React from "react";
import { kebabCase } from "lodash";
import { Link } from "gatsby";

import "./PostTags.css"

function PostTags({ tags }) {
  return (
    <div className="post-tag-container">
      {tags &&
        tags.map((tag) => (
          <Link
            key={tag}
            className="tag"
            to={`/tags/${kebabCase(tag)}`}
          >
            <p>{tag}</p>
          </Link>
        ))}
    </div>
  );
}

export default PostTags;
